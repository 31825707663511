<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside=closeDialog>
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col md="4">
          <v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="right" v-if=!readonly>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            rounded
          >
            Novo modelo
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <h5 class="bold primary--text">{{ hasItemId ? 'Modelo' : 'Novo modelo' }}</h5>
          </v-col>
          <v-col align="right">
            <v-btn color="black" right icon dark @click=closeDialog>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text v-if=loading>
        <v-container>
          <v-row justify="center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-else>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.nome"
                label="Nome"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.titulo"
                label="Título"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.descricao"
                label="Descrição"
                auto-grow
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click=closeDialog
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click=save
          :loading=saving
          rounded
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

function initialState () {
  return {
    dialog: false,
    saving: false,
    loading: false,
    search: null,
    form: {
      id: null,
      nome: '',
      titulo: '',
      descricao: ''
    }
  }
}

export default {
  name: 'FeedbackModelFormDialog',
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    ...initialState()
  }),
  watch: {
    hasItemId (val) {
      if (this.exists(val)) {
        this.dialog = true
        this.loading = true
        this.form = this.item
        this.loading = false
      }
    },
    search (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    hasItemId () {
      return this.item && this.item.id !== null
    }
  },
  methods: {
    ...mapActions([
      'saveFeedbackModel'
    ]),
    save () {
      this.saving = true
      this.saveFeedbackModel(this.form)
        .then(() => {
          this.$emit('reloadList')
          this.closeDialog()
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.saving = false
        })
    },
    closeDialog () {
      Object.assign(this.$data, initialState())
      this.$emit('cleanData')
      this.dialog = false
      return true
    }
  }
}
</script>
