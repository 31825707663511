<template>
  <div>
    <v-card elevation="6" class="pa-3">
      <v-subheader class="ma-0 pa-0">
        <h4 class="bold primary--text">Modelos de pareceres</h4>
      </v-subheader>
      <br />
      <v-data-table
        v-if=table
        :headers="headers"
        :items="tableContent"
        sort-by="name"
        class="elevation-0"
        :items-per-page="10"
        no-data-text="Nenhum dado encontrado"
        no-results-text="Nenhum resultado encontrado"
        :footer-props="{
          'items-per-page-text': 'Itens por página:',
          'items-per-page-all-text': 'Todos',
          'items-per-page-options': [5, 10, 15, 20, -1],
          'page-text': '{0}-{1} de {2}',
        }"
        :search="search"
        :loading="loading"
        loading-text="Carregando dados..."
      >
        <template v-slot:top>
          <v-toolbar flat>
            <FeedbackModelFormDialog v-model=search :item=itemGlobal @cleanData=resetItem @reloadList=reloadTable></FeedbackModelFormDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editItem(item)" title="Editar">
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <DeleteItemDialog
            @delete="removeModel(item)"
            :loading=loadingDelete
            :text="'Tem certeza de que deseja excluir esse modelo? <br>Esta ação não poderá ser desfeita!'"
            :btnStyle="deleteBtnStyle"
          ></DeleteItemDialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FeedbackModelFormDialog from './FormDialog'
import DeleteItemDialog from '../Generics/DeleteItemDialog'

export default {
  name: 'RealEstateTable',
  components: {
    FeedbackModelFormDialog,
    DeleteItemDialog
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Título', value: 'titulo' },
      { text: 'Descrição', value: 'descricao' },
      { text: 'Ações', value: 'actions', sortable: false }
    ],
    loading: false,
    itemGlobal: null,
    table: true,
    loadingDelete: false,
    deleteBtnStyle: {
      small: true,
      color: 'error'
    }
  }),
  computed: {
    ...mapGetters([
      'feedbackModelList'
    ]),
    tableContent () {
      return this.feedbackModelList
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions([
      'getFeedbackModelList',
      'deleteFeedbackModel'
    ]),
    initialize () {
      this.loading = true
      this.getFeedbackModelList()
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setSearch (val) {
      this.search = val
    },
    editItem (item) {
      this.itemGlobal = item
    },
    resetItem () {
      this.itemGlobal = null
    },
    reloadTable () {
      this.initialize()
    },
    removeModel (item, i) {
      this.loadingDelete = true
      this.deleteFeedbackModel(item.id).then((resp) => {
        this.$toastr.s(resp.message)
        this.reloadTable()
      })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingDelete = false
        })
    }
  }
}
</script>
