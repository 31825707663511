<template>
  <FeedbackModelTable></FeedbackModelTable>
</template>

<script>
import FeedbackModelTable from '../../components/FeedbackModel/Table'

export default {
  components: {
    FeedbackModelTable
  }
}
</script>

<style>
</style>
